*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

/* button {
    cursor: pointer;
} */


/* this section is for the sidemenu */
.side-menu {
    box-sizing: border-box;
    min-width: 20rem;
    background-color: var(--primary-color-blue);
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.4s;
}


.side-menu 
#header {
    width: 100%;
    height: 4rem;
    background-color: var(--primary-color-yellow);
    color: var(--primary-color-blue);
    padding-inline: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1px 1px 5px  black;

}
#header >div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
#logo{
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--primary-color-blue);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


}
#logo img {
    width: 100%;
}

h2.input {
    color: var(--primary-color-blue);
}

#side-menu-FaBars{
    color: var(--primary-color-blue);
    font-size: 1.8rem;
    cursor: pointer;
    border: 1px solid var(--primary-color-blue);
    padding: 0.1rem;
    border-radius: 0.3rem;
    transition: 0.2s;

}

#side-menu-FaBars:hover{
    padding: 0.2rem;
    opacity: 0.8;
}

.sideNavLinks {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
}
.side-menu .sideNavLinks::-webkit-scrollbar{
    /* display: none; */
    width: 0.5rem;
}
ul
#Link {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: white;
    height: 4rem;
    padding-inline: 1.3rem;
}

.side-menu-item
#Link:hover {
    background-color: rgb(2, 18, 39)
}



#Link:hover 
#side-menu-icon,
#side-menu-down-arrow{
    color: var(--primary-color-yellow);
}
#Link:hover 
#side-menu-down-arrow{
    color: var(--primary-color-yellow);
}
.side-menu-item {
    display: flex;
    gap: 2rem;
    align-items: center;
    text-wrap: nowrap;
    flex-wrap: nowrap;
}
#side-menu-icon {
    font-size: 1.2rem;

}
#side-menu-down-arrow {
    transform: rotate(270deg);

}
li {
    list-style: none;
    font-size: 1rem;
    text-wrap: nowrap;
}

.nav
 .active
{
    color: var(--primary-color-yellow);
    background-color: #021329;
    position: sticky;
    top: 0px;
    z-index: 4;
}

.nav .active
#side-menu-icon
{
    color: var(--primary-color-yellow);
}

.nav .active
#side-menu-down-arrow{
    color: var(--primary-color-yellow);
}

.action-buttons .active
#actionbutton{
    background-color: var(--primary-color-yellow);
    color: var(--primary-color-blue);
}
#side-menu-down-arrow {
    color: white;
    position: relative;

}





















/* this section is for the logout section */
.Logout {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgb(2, 18, 39);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.Logout
.logout-pallet {
    background-color: #021329;
    border: 1px solid var(--primary-color-yellow);
    width: 100%;
    max-width: 30rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 0.5rem;
    color: white;
}


p{
    font-size: 1.5rem;
    color: white;
}

.logout-buttons {
    display: flex;
    width: 100%;
    gap: 10%;
    justify-content: center;
}

.logout-buttons button {
    width: 10rem;
    border: 0px;
    color: white;
    /* border: 1px solid white; */
    font-weight: bold;
    font-size: 1rem;
    background-color: var(--primary-color-yellow);
    
}
.logout-buttons button:hover {
    /* border-color: var(--primary-color-yellow); */
    /* border: none; */
    font-size: large;
}








/* this is the loading screen  */
.loading {
   position: absolute;
   top: 0px;
   background-color: rgba(1, 10, 51, 0.7);
   width: 100%;
   height: 100%;
   z-index: 16;
   backdrop-filter: blur(5px);
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 5px;
   /* min-height: 5rem; */

}

.loading img{
    width: 5rem;
}

button {
    position: relative;
}