*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.fee-collection,.add-income, .all-income,.all-expenses {
    display: flex;
    flex-direction: column;
    height: auto;
    /* gap: 1rem; */
    max-width: 100%;
    overflow-x: auto;
    /* background-color: red; */
}


.add-income select, .add-expense select {
    margin: 0px;
}
.fee-collection .nav .active{
    background-color: transparent;
}
.add-income .nav .active{
    background-color: transparent;
}
.all-income .nav .active {
    background-color: transparent;

}
.all-expenses .nav .active {
    background-color: transparent;

}
.add-expense .nav .active{
    background-color: transparent;

}


.finance-head {
    position: sticky;
    left: 0px;
    display: flex;
    flex-direction: column;
}








.add-income-body {
    /* border: 1px solid rgb(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    padding: 1rem;
    margin-top: 1rem; */
    /* background-color: red; */
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    /* flex-direction: column; */
    max-width: 100%;
    gap: 1rem;

}


.add-income textarea {
    width: 100%;
    max-width: 100%;
    max-height: 3rem;
    margin: 0px;
}


.receipt-pallet {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    position: fixed;
    right: 0rem;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0,0.3);
    backdrop-filter: blur(10px);
    z-index: 19;
    overflow: auto;

}
.receipt {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 24rem;
    background-color: white;
    min-width: 50rem;
    max-width: 60rem;
    margin-inline: auto;
    margin-top: 1rem;
    z-index: 9;
    /* margin: 2rem; */
    padding-top: 3rem;
    border-radius: 0.5rem;
    overflow: auto;

}
.receipt .school-logo {
    width: 10rem;
    height: 10rem;

}
.receipt img {
    width: 3rem;

}


.receipt .school-header {
    display: flex;
    justify-content: space-between;
    max-height: 13rem;
    text-align: center;
    padding-inline: 3rem;
}

.receipt-info {
    text-align: left;
    padding-top: 0.5rem;
    padding-inline: 3rem;

}
.receipt-info >div{
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-inline: 1rem;

}

.receipt label {
    font-size: 1rem;
}

.receipt-action  {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.4rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 75;
    
    

}

.receipt-action >div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color-yellow);
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    opacity: 0.8;
    box-shadow: 1px 1px 5px rgb(0, 0, 0,0.3);
}

.receipt-action >div:hover {
    transition: 0.4s;
    opacity: 1;
}
