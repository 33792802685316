*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.attendance {
    max-width: 80rem;
    display: flex;
    flex-direction: column;

}



