*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}


.grades table input {
    margin: 0.3rem 0rem;
    border-radius: 0rem;
    min-width: 100%;
    max-width: 4rem;
}

.grades table th {
    text-align: center;
}