*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.all-classes  {
    /* background-color: red; */
    /* margin: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 100%;
    overflow: auto;
    min-height: 80rem;



}
.add-class .container{
    max-width: 100%;
    overflow: auto;
}
.class-body {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    position: sticky;
    left: 0px;
    /* background-color: blue; */
    /* display: none; */

}
.class-body >div {
    width: 100%;
    max-width: 70rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-inline: 1rem ;
}
.class-level {
    /* background-color: var(--primary-color-blue); */
    background-color: rgba(20, 74, 81, 0.5);
    background-color: #626e73;

    color: white;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
    border-right: 0.3rem solid var(--primary-color-yellow);
    border-left: 0.3rem solid var(--primary-color-yellow);
    padding-left: 0.5rem;

}

.class {
    flex: 1;
    width: 15rem;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    min-width: 10rem;
    max-width: 25rem;
    /* background-color: var(--primary-color-blue); */
    box-shadow: 1px 1px 5px rgb(3, 28, 59,0.4);
    color: var(--primary-color-blue);
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    border-radius: 0.3rem;
    border: 0px;

}

.class-actions {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.class:hover {
    background-color: var(--primary-color-yellow);
    color: var(--primary-color-blue);
    background-color: rgb(0, 0, 0, 0.1);
    font-size: larger;
}






.class-details {
    /* margin: 1rem; */
    max-width: 100%;
    /* min-width: 100%; */
    overflow: auto;
    box-sizing: border-box;
    /* background-color: red; */
    

}
.details-header {
position: sticky;
left: 0px;

}

