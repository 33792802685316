*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow: auto; */
}
#blue {
    color: var(--primary-color-blue);
}

.all-users {
    display: flex;
    flex-direction: column;
    height: auto;
    max-width: 100%;
    overflow-x: auto;

}


 
.user-features {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: sticky;
    left: 0rem;
}


#no-data-error {
    color: red;
    border: 1px solid red;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;

}


::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
    background-color: rgb(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0, 0.4);
    border-radius: 0.3rem;
}

.action-buttons {
    background-image: url('/src/images/images/cubes.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    /* background-color: var(--primary-color-blue); */
    
    /* background-color: rgb(0, 0, 0, 0.6); */
    display: flex;
    flex-wrap: nowrap;
    max-height: 4rem;
    min-height: 4rem;
    min-width: 100%;
    box-sizing: border-box;
    gap: 0.5rem;
    padding: 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    left: 0px;
    overflow: auto;
}

.action-buttons::before {
    content: '';
  width: 100%;
  height: 100%;
  background-color: rgb(3, 28, 58,0.7);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  left: 0px;
}

.action-buttons button{
    border: 1px solid var(--primary-color-yellow);
    padding: 0.5rem;
    text-wrap: nowrap;
    /* height: 2.3rem; */
}

.action-buttons button:hover{
    color: var(--primary-color-yellow);
    /* border: 1px solid rgba(255, 255, 255); */
    /* padding-inline: 1rem; */
}


input[type='text'],input[type='email'],input[type='date'], input[type='number'], input[type='tel'] {
    height: 2rem;
    border: none;
    border-radius: 0.3rem;
    flex: 1;
    min-height: 2.5rem;
    min-width: 10rem;
    padding-inline: 1rem;
}

.passwordInput {
    display: flex;
    position: relative;
}

#togglePasswordVisibility {
   position: absolute;
   right: 1rem;
   top: 0.8rem;
   opacity: 0.6;
    color: var(--primary-color-blue);
    background-color: white;
    cursor: pointer;
   /* padding-top: 0.3rem; */
   /* background-color: red; */
   /* margin-top: 1rem; */
}
textarea {
    width: 100%;
    max-width: 40rem;
    min-width: 60%;
    max-height: 20rem;
    min-height: 5rem;
    border: 0px;
    border-radius: 0.3rem;
    margin-bottom:1rem;
    padding: 1rem;
    box-sizing: border-box;
}

select{
    height: 2rem;
    border: none;
    border-radius: 0.3rem;
    margin: 0px;
    box-sizing: border-box;
    min-height: 2.5rem;
    padding-inline: 1rem;
    flex: 1;


} 
button:disabled, select:disabled, input:disabled   {
    cursor: not-allowed;
}

select option:nth-child(even){
    background-color: rgb(0, 0, 0, 0.2);
}


.sticky-left {
    position: sticky;
    left: 0px;
}


.sorting {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    padding: 0.5rem;
    border-radius: 0.3rem;
    /* overflow: hidden; */
    border: 1px solid rgb(0,0,0,0.3);
    /* transition: 1s; */
    background-color: rgb(0, 0, 0, 0.2);
    background-color: rgb(197, 219, 229);

}

.sorting-body {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    /* background-color: red; */
    justify-content: space-evenly;
    overflow: auto;
    position: relative;

}

.sorting .input {
    background-color: rgba(0, 0, 0, 0.3);
    flex: 1;
    min-width: 18rem;
    min-height: 3rem;
    padding-inline: 0.3rem;
    border-radius: 0.3rem;
}
.sorting .half {
    min-width: 40%;
}
.sorting .sorting-header {
    /* background-color: var(--primary-color-blue); */
    background-image: url('/src/images/images/cubes.jpg');
    background-size: cover;
    background-position: center;
    background-color: rgb(0, 0, 0, 0.4);
    /* background-color: rgba(15, 80, 104, 0.7); */
    color: white;
    height: 2.5rem;
    min-width: 100%;
    border-radius: 0.3rem;
    padding-inline: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
}

.sorting .sorting-header::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(3, 28, 58,0.7);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0px;
    left: 0px;
}

.sorting .sorting-header h2{
    color: white;
    z-index: 2;
}
#sorting-down-arrow{
    font-size: larger;
    z-index: 2;
}
label{
    /* max-width: 3.7rem; */
    /* min-width: 3.7rem; */
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
}

.sorting h2{
    color: var(--primary-color-blue);
}

.entry-table {
    max-width: 100%;
    overflow: auto;
}

table {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    /* max-width: 100%; */
    box-sizing: border-box;
    /* overflow: auto; */
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    background-color: rgb(0, 0, 0, 0.1);
    background-color: rgb(197, 219, 229);

}

 thead {
    position: sticky;
    top: 0rem;
    z-index: 3;
    
}

th {
    width: auto;
    /* min-width: 10rem; */
    text-wrap: nowrap;
    height: 3rem;
    text-align: left;
    /* background-color: var(--primary-color-blue); */
    /* background-color: rgb(0, 0, 0, 0.5); */
    padding-inline: 1rem;
    box-sizing: border-box;
    color: white;
    /* background-color: #626e73; */
    background-image: url('/src/images/images/cubes.jpg');
    background-size: cover;
    background-position: center;
    z-index: 2;
    position: relative;
    color: white;
}

th::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(3, 28, 58,0.7);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
}
#inner-th {
    width: auto;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}
#inner-th th {
    padding: 0px;
    line-height: 2rem;
    flex: 1;
    font-weight: bolder;
    /* background-color: green; */
}

 tbody {
    overflow: hidden;
    /* background-color: black; */
    /* max-height: 20rem; */
    /* z-index: ; */

}


 tbody tr:nth-child(even)  {
    /* background-color: rgb(170, 172, 173); */
    background-color: rgb(0, 0, 0,0.1);

}

 tbody tr:nth-child(odd)  {
    /* background-color: rgb(0, 0, 0,0.1); */
    
    color: var(--primary-color-blue);
    

}
tbody
 tr {
    /* background-color: red; */
    cursor: pointer;
    transition: 0.3s;
    /* line-height: 2.5rem; */
    /* min-height: 2rem; */
    /* max-height: 2rem; */
    
}
tbody
td {
    /* min-height: 2rem; */
    max-height: 2rem;
    min-height: 2rem;
}

.grade-header   {
    background-color: rgb(0, 0, 0, 0.2);
    color: var(--primary-color-blue);
    text-align: center;
    font-size: 1.8rem;
}


 tbody tr:nth-child(odd):hover,
tbody tr:nth-child(even):hover, 
tr:hover {
    background-color: rgb(0, 0, 0, 0.4);
    color: white;
}

#inner-td {
    height: 2rem;
    /* background-color: red; */
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
#inner-td td {
    padding: 0px;
    /* line-height: 3rem; */
    font-weight: bold;
}
#inner-td #paid{
    /* color: green; */
    color: var(--primary-color-blue);

}
#inner-td #balance{
    text-align: right;
    color: var(--primary-color-blue);

    /* background-color: red; */
}
td {
    min-width: 5rem;
    /* max-width: 10rem; */
    overflow: auto;
    padding-inline: 1rem;
    /* height: 2.5rem; */
}

#table-actions {
   
    /* height: 2rem; */
    /* line-height: 2.5rem; */
}

 #user-name-head {
    position: sticky;
    left: 0px;
    z-index: 2;
    background-color: var(--primary-color-yellow);
    color: var(--primary-color-blue);
    color: white;
}

#user-name {
    background-color: var(--primary-color-blue);
    position: sticky;
    color: white;
    left: 0px;
    top: 0px;
    z-index: 1;
    text-wrap: nowrap;
    min-width: 10rem;
    background-color: #626e73;

}

#no-wrap {
    text-wrap: nowrap;
}
#bold {
    font-weight: bolder;
}
#text-align-right {
    text-align: right;
}
button:disabled {
    opacity: 0.5;
}

#user-actions-head {
    z-index: 4;
    position: sticky;
    right: 0px;
    background-color: var(--primary-color-yellow);
    box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.2);
    color: var(--primary-color-blue);
    color: white;
}

#table-actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    background-color: var(--primary-color-blue);
    color: white;
    position: sticky;
    right: 0px;
    min-height: 3rem;
    flex: 1;
    z-index: 1;
    box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.2);
    background-color: #626e73;


}
#action-see{
    color: greenyellow;
}
#action-update {
    color: white;
}
#action-delete {
    color: var(--primary-color-yellow);
}


.academic-report td, .academic-report th {
    text-align: center;
}
.academic-report table{
    background-color: transparent;
}
.academic-report tr:nth-child(even) {
    background-color: transparent;
}


.academic-term table  .main-td {
 display: flex;
 flex-direction: column;
 background-color: red;
 min-height: 6rem;
}

.academic-term .main-td td{
    min-height: 2rem;
}







.add-student {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 1rem;
    max-width: 100%;
    overflow-x: auto;
}


.add-student-form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

}
form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* gap: 1rem; */
}
.add-student form h2 {
    height: 2rem;
    line-height: 2rem;
    /* background-color: red; */
}
/* form */
.student-information,
.parent-information,
.contact-details {
    width: 100%;
    min-width: 15rem;
    max-width: 90rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    flex: 1;
    display: flex;
    flex-wrap: wrap;

}
 .input{
    background-color: rgb(0, 0, 0, 0.1);
    /* background-image: url('/src/images/images/cubes.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0.3rem ;
    border-radius: 0.3rem;
    min-width: 25rem;
    display: flex;
    flex: 1;
    align-items: center;
    overflow: auto;
    position: relative;

}

/* .input::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(3, 28, 58,0.7);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0px;
    left: 0px;
} */

.add-student input, .add-teacher input ,.add-student select, .add-teacher select {
    flex: 1;
    margin: 0px;
    background-color: rgb(256, 256, 256,0.8 );
}

/* input,select,textarea,label {
    z-index: 5;
    border: none;

    
} */

input:focus, select:focus, textarea:focus {
    outline: 1px solid var(--primary-color-blue);
}
.input label {
    /* color: white; */
}
.add-student input, .add-student select {
    margin-top: 0rem;
}


#submit-reset-buttons {
    display: flex;
    gap: 1rem;
    min-width: 15rem;
    flex-wrap: wrap;
    justify-content: left;
    /* margin-top: 1rem; */

    

}





/* this is for the add teacher section  */

.add-teacher {
    /* padding: 1rem; */
    max-width: 100%;
    /* background-color: red; */
}
.teacher-info, 
.sch-details, 
.financial-details, .id-contact-details {
    /* background-color: green; */
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
    padding-bottom: 1rem;
    /* flex-direction: column; */

}

.add-teacher select {
    margin: 0px;
}


.input-category {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    min-width: 20rem;
}

.subject-options {
    background-color: red;
    min-width: 100%;
    height: 9rem;
}
.subject-options input[type="checkbox"] {
    width: 0.5rem;
    height: 0.5rem;
}





/* this is for the financial action buttons  */





/* this section is for the grades */
.grades {
    box-sizing: border-box;
}


.student-details, .teachers-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.1rem 1rem;
    padding-bottom: 2rem;
}

#user-details-icon {
    font-size: 5rem;
    color: var(--primary-color-blue);
}

.user-header {
    width: 100%;
    max-width: 80rem;
    height: 8rem;
    background-color: aliceblue;
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    position: relative;
    background-image: url('../../images/images/cubes.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem;
    border-radius: 0.3rem;
    overflow: hidden;
}

.user-header .overlay {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgb(3, 28, 59,0.8);
}

.user-header #user-details-icon {
    z-index: 5;
    color: white;
}

.user-header .personal-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: white;
    z-index: 2;
}

.user-header .personal-details label {
    font-size: 1.1rem;
}


.info {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    column-gap: 1rem;
    flex-wrap: wrap;
    max-width: 80rem;
}
.content-loader .container {
    max-width: 100%;
    overflow: auto;
}

 .img-container {
    height: 6rem;
    width: 6rem;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    background-color: rgb(197, 219, 229);


}
.container .user-table #user-icon {
    color: var(--primary-color-blue);
}

.personal-info, .financial-info, .payment-receipt-info , .other-info {
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    min-width: 20rem;
    flex: 1;
    /* padding-inline: 1rem; */
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    max-height: 25rem;
    overflow: auto;
    position: relative;
    /* background-color: red; */
    box-sizing: border-box;
}

.personal-info h3,.financial-info h3, .payment-receipt-info h3 ,.other-info h3 {
    width: 100%;
    border-radius: 0.3rem;
    position: sticky;
    padding-inline: 1rem;
    top: 0px;
    z-index: 6;
    height: 3rem;
    min-height: 3rem;
    background-image: url('../../images/images/cubes.jpg');
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.personal-info h3::after,.financial-info h3::after, .payment-receipt-info h3::after, .other-info h3::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(3, 28, 59,0.8);
    z-index: -1;

}


.personal-info label , .financial-info label, .payment-receipt-info label , .other-info label {
   min-width: 100%;
   margin: 0rem;
   height: 2.5rem;
   background-color: white;
   box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.2);
   padding-inline: 1rem;
   line-height: 2.5rem;
   min-height: 2.5rem;
   /* text-wrap: nowrap;
   word-wrap: normal;  */
   overflow: hidden;
   max-width: 100%;
   transition: 0.3s;
   cursor: pointer;
}

.financial-info >div {
    width: 100%;
    display: flex;
    flex-direction: column;

}
.financial-info-head {
    min-width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    padding-inline: 1rem;
    font-weight: bold;
    background-color: white;
    color: var(--primary-color-blue);
    margin-bottom: 0.3rem;
    position: sticky;
    top: 3rem;
    
}
.financial-info b {
    color: var(--primary-color-blue);
    padding-left: 1rem;
    padding-bottom: 0.3rem;
    font-weight: normal;
}
.edit-info-notice {
    display: flex;
    height: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--primary-color-blue);
    
}

.payment-receipt-info label:hover {
    background-color: var(--primary-color-blue);
    color: white;
}








/* .attendance  */

#attendance-checkbox {
    min-height: 2.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}
#attendance-checkbox button {
    height: 2rem;
}
















.report {
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
    /* max-width: 100%; */
    
}



.academic-report {
    margin-inline: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    /* min-width: 55rem; */
    max-width: 65rem;
    min-height: 80rem;
    overflow: auto;
    border: 1px solid black;
    background-color: white;
    /* box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.5); */
}

.academic-report table td{
    font-weight: bolder;
}
.academic-report-features {
    padding: 1rem;
    background-color: var(--primary-color-blue);
    border-radius: 0.3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    /* margin-top: 1rem; */
    align-items: center;
    background-color: green;
}

.academic-report-features label{
   color: var(--primary-color-yellow);
   /* width: 100%; */
}
.academic-report-features .input {
    min-width: 15rem;
}

.academic-report-features button {
    margin: 0px;
    border: 2px solid var(--primary-color-yellow);
}



.academic-report .school-header {
    display: flex;
    border-bottom: 1px solid black;
    /* background-color: red; */
}

.report-details {
    display: flex;
    flex-wrap: wrap;
    font-weight: bolder;
    font-weight: 800;
    /* background-color: red; */
    /* padding-inline: 2rem; */
    /* background-color: red; */
}

.academic-report p {
    font-size: 1.2rem;
    color: var(--primary-color-blue);

}
.academic-report tr {
    height: 2.5rem;
}
.academic-report .school-details {
    /* background-color: green; */
    text-align: center;
    flex: 1;
}
.academic-report .school-details h3 {
    font-size: 2rem;
    padding-top: 0.5rem;
    font-weight: bolder;
}
.academic-report .school-details h4 {
    font-size: 1.4rem;
    font-weight: bolder;


}
.academic-report .school-logo {
    width: 12rem;
    height: 10rem;
    /* background-color: red; */
}
.school-logo img {
    width: 100%;
    height: 100%;
    /* background-color: green; */
}

.report-details {

}
.term-summary {
    padding-bottom: 1rem;
    display: flex;
    /* gap: 5rem; */
    flex-wrap: wrap;
    font-weight: bolder;

}
.classteacher-section, .headmaster-section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    /* gap: 5rem; */
}

.report-details label, .term-summary label, .classteacher-section label, .headmaster-section label  {
    /* background-color: red; */
    width: 50%;
    min-width: 40%;
    padding-bottom: 1rem;
    flex: 1;
    padding-inline: 1rem;
    font-size: 1.1rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;

    /* font-weight: bold; */
}
.report-details label em, .term-summary label em, .classteacher-section label em, .headmaster-section label em  {
    font-size: 1.1rem;
    font-weight: bold;
    font-style: normal;
    
}
.post-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bolder;

}








.deletePrompt  {
    background-color: rgb(0, 0, 0,0.8);
    backdrop-filter: blur(0.2rem);
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 23;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.deletePallet {
    max-width: 30rem;
    /* background-color: var(--primary-color-blue); */
    background-color: rgba(15, 78, 107, 0.1);
    border: red solid 2px;
    backdrop-filter: blur(0.3rem);
    position: relative;
    padding: 1.8rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#closeButton    {
    position: absolute;
    top: 2px;
    right: 2px;
    color: white;
    padding: 0.3rem;
    background-color: red;
    border-radius: 1rem;
    width: 2rem;
    text-align: center;
    cursor: pointer;
}
.deletePallet p {
    font-size: 1rem;
    color: white;
}
.deletePallet button{
    border: 0px solid red;
    background-color: red;
    height: 2.3rem;
    width: 100%;
}
.deletePallet button:hover{
    color: wheat;
}

.deletePallet #submit-reset-buttons{
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
button:hover {
    opacity: 0.9;
}
.deletePallet input {
    width: 100%;
    margin-top: 0.5rem;
}