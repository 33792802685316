
 :root {
  --primary-color-blue :   #031c3b;
  --primary-color-yellow: #f47c2c;
  
 }
 html {
  font-size: 14px;
  cursor:default;
 }
 
 *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
 }

.App {
  display: flex;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
  overflow: auto;
  
}

input:focus {
  outline: 1px solid var(--primary-color-yellow);
}
textarea:focus {
  outline: 1px solid var(--primary-color-yellow);
}
select:focus {
  outline: 1px solid var(--primary-color-yellow);
}

.sign-in-pallet {
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--primary-color-blue);
  z-index: 6;


}
.sign-in-pallet #school-logo {
  width: 6rem;
}
#sch-name {
  font-size: 1.5rem;
  color: var(--primary-color-yellow);
}
#login-h2{
  font-size: 1.5rem;
  color: white;
  margin-bottom: 1rem;

}
.sign-in-pallet .sign-in-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--primary-color-yellow);
  max-width: 30rem;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  /* margin-top: 2rem; */
  /* box-shadow: 1px 1px 15px rgb(5, 42, 84,0.3); */

  

}
.sign-in-pallet .sign-in-form 
input {
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  padding-inline: 1rem;
  /* margin-bottom: 1rem; */
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
  min-width: 18rem;
  /* background-color: red; */
}

.sign-in-pallet #form-button {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 0px ;
  background-color: var(--primary-color-yellow);
  /* color: var(--primary-color-blue); */
  color: white;
  font-size: 1.1rem;

}

.sign-in-pallet #form-button:hover {
  /* font-size: 1.2rem; */
  background-color: #d96518;

}


.main-menu {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  position: relative;
  gap: 0.3rem;
  background-color: rgb(197, 219, 229,0.5);

  /* background-color: red; */
  
  

}
.main-menu::-webkit-scrollbar {
  display: none;
}





/* this section is for th main dashboard  */
.main-dash-board {
  /* background-color: red; */
  /* background-color: green; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
  /* max-height: 95%; */
  flex: 1;
  overflow: auto;
}




.dash-pane{
  /* background-color: green; */
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
  /* max-height: 100%; */
  overflow: auto;
  /* flex-wrap: wrap; */
  /* background-color: red; */
}


.dash-pane
.container {
  /* margin-top: 0.5rem; */
  max-height: 100%;
  /* max-height: 100%; */
  overflow: auto;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  color: white;
  max-width: 70rem;
  min-width: 20rem;
  flex: 2;
  /* background-color: orange; */
}

.main-dash-board .container ::-webkit-scrollbar{
  display: none;
}


.content-loader{
  /* background-color: yellow; */
  margin-inline: 1rem;
  max-width: 100%;
  overflow: auto;
  box-sizing: border-box;
  /* max-height: 20vh; */
  /* margin-top: 0.2rem; */
  /* padding-bottom: 1rem; */
  margin-bottom: 0.5rem;
  position: relative;
  /* flex: 1; */
  /* background-color: rgb(197, 219, 229); */

}




.Header {
  min-width: 100%;
  max-height: 4rem;
  min-height: 4rem;
  background-color: #031c3a;
  color:  white;
  color: var(--primary-color-yellow);
  padding-inline: 1rem;
  /* margin-inline: 1rem; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  position: sticky;
  top: 0px;
  overflow: auto;

  
}

.Header
.header-features {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  gap: 1.4rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  text-wrap: nowrap;
  /* background-color: red; */
}
h2{
  font-size: 1rem;
  text-wrap: nowrap;

}
/* .search {
  
  height: 1.7rem;
  display: flex;
  align-items: center;
  position: relative;


}
.search
#header-input {
  border: none;
  background-color: white;
  border-radius: 1rem;
  height: 100%;
  border: none;
  padding-inline: 1rem;
  padding-inline-end: 2rem;
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
}
input:active{
border: none;
} */

button {
  width: auto;
  height: 3rem;
  padding-inline: 1rem;
  border: none;
  border-radius: 0.2rem;
  background-color: var(--primary-color-blue);
  color: white;
  transition: 0.3s;
  cursor: pointer;
}

button:hover {
  color: var(--primary-color-yellow);
}





#search-icon {
  position: absolute;
  right: 0.5rem;
  color: var(--primary-color-blue);
  /* background-color: red; */

}

.Header .user {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border-left: 1px solid ; */
  padding-inline-start: 1rem;
  /* background-color: red; */
}

#user-icon{
  border: 1px solid;
  padding: 0.2rem;
  border-radius: 50%;
  width: 1.5rem;
  height: auto;
  color: var(--primary-color-yellow);


}



h1{
  color: white;
  background-color: var(--primary-color-blue);
  /* background-color: rgb(0, 0, 0, 0.2); */
  padding-inline: 1rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  line-height: 2.5rem;
  border-right:  5px solid var(--primary-color-yellow);
  border-left: 5px solid var(--primary-color-yellow);
}

.dashboard-summary {
  min-width: 18rem;
  display: flex;
  gap: 1rem;
  flex-wrap:  wrap ;
  border-radius: 0.3rem;
  padding-top: 0.8rem;
  justify-content: space-evenly;
  flex: 3;
  margin-bottom: 3rem;
  position: relative;
  /* background-color: green; */

}
.dashboard-summary:last-child{
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}


.summary-item {
  position: relative;
  flex: 1;
  min-width: 18rem;
  margin-inline: 0.5rem;
  color: var(--primary-color-blue);
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0.3px 0.3px 5px rgb(3, 28, 59,0.6);
  background-image: url('../src/images/images/cubes.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  overflow: hidden;
  opacity: 0.9;
  /* border: 1px solid rgb(0,0,0,0.2); */

}

.summary-item:hover {
  opacity: 1;
}

.summary-item::before{
  content: '';
  width: 110%;
  height: 110%;
  background-color: rgb(3, 28, 58,0.8);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  left: 0px;

}
.dashboard-summary:first-of-type .summary-item {
  min-width: 14rem;

}


.summary-item b{
  text-wrap: nowrap;
  z-index: 40;
  color: white;
}

#item-header {
  /* background-color: var(--primary-color-blue); */
  /* background-color: rgb(3, 28, 58,0.7); */
  /* background-color: rgba(17, 49, 59, 0.6); */
  /* color: var(--primary-color-blue); */
  color: var(--primary-color-yellow);
  z-index: 40;
  width: 100%;
  height: 2.8rem;
  font-size: 0.8rem;
  line-height: 2.8rem;


}
#item-header hr{
  /* color: rgb(3, 28, 58,0.3); */
  /* opacity: 0.2; */
  /* box-shadow: 1px 1px 2px black; */
  margin-inline: 1rem;
  outline: 1px solid transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* border-width: 1px; */
}

#item-body{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* background-color: red; */
  padding-inline: 1rem;

}

#value {
  padding-inline: 1rem;
  /* border-right: 1px solid rgb(0, 0, 0, 0.2); */
  color: rgba(17, 49, 59, 0.6);
  color: white;

  line-height: 4rem;
  font-size: 2.4rem;
  font-weight: 900;
}

#item-description {
  /* background-color: #fdc400; */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 0.5rem;
  text-wrap: nowrap;

}

.dashboard-summary 
.summary-item:hover {
  /* background-color: var(--primary-color-yellow); */
  background-color: rgb(255, 196,0);
  background-color: rgb(0, 0, 0, 0.1);
}
.dashboard-summary 
.summary-item:hover #item-header {
  color: white;
  color:  var(--primary-color-yellow);
}











/* this is for the notice board  */
.notice-recent-activities{
  /* margin-top: 0.5rem; */
  border-radius: 0.3rem;
  max-width: 50rem;
  min-width: 20rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  background-color: rgb(0, 0, 0, 0.2);

  /* background-color: aqua; */

}
.notice-recent-activities ::-webkit-scrollbar-track {
  margin-top: 2.5rem;
}
.noticeboard, .recent-activities {
  width: auto;
  min-width: 100%;
  /* max-height: 100%; */
  overflow: auto;
  flex: 2;
  color: #031c3a;
  transition: 0.5s;


}


#notice-board-header,
#recent-activities-header {

  background-color: var(--primary-color-blue);
  border-right: 5px solid var(--primary-color-yellow);
  /* color: white; */
  border-left: 5px solid var(--primary-color-yellow);
  height: 2.5rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
  padding-inline: 1rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  cursor: pointer;
  text-wrap: nowrap;

}

#notice-board-header
{
position: absolute;
width: 100%;
}




.notice-body,
.activities-body{
  position: relative;
  /* top: 1rem; */
  box-sizing: border-box;
  color: #031c3a;
  padding-right: 0.5rem;

}
.notice-body{
  top: 2rem;

}



.activity, .message{
  border-bottom: 1px solid rgb(0,0,0,0.2);
  position: relative;
}

.message:nth-child(),.activity:nth-child(even){
  background-color: rgb(229, 240, 239);
  border-bottom: 1px solid rgb(0,0,0,0);



}
.message h3 {
  /* background-color: red; */
}

.message p {
  color: #031c3a;
}


.itemcounter{
  width: auto;
  padding: 0.5rem;
  background-color: rgb(3, 28, 58,0.7);
  background-color: var(--primary-color-yellow);
  color: white;
  color: #031c3a;
  position: absolute;  
  right: 0.6rem;
  top: 1rem;
  border-radius: 0.3rem;
}


/* this is for the recent activities  */
.recent-activities {
  flex: 2;
  height: auto;
}

.activity {
  /* padding-inline: 1rem; */
  padding: 1rem;
}




/* this section is for the progress bar on the header section  */
.progressBar {
  position:  absolute;
  bottom: 0px;
  left: 0px;
  min-width: 90%;
  height: 0.6rem;
  /* border-left: 4px solid var(--primary-color-blue); */
  /* border-right: 4px solid var(--primary-color-blue); */
  /* background-color: var(--primary-color-blue); */
  display: flex;
  align-items: center;
  margin-inline: 1rem;
  box-sizing: border-box;

}

.progressPercentage {
  transition: 1s;
  transition-timing-function: ease-in;
  width: 0%;
  height: 0.2rem;
  /* background-image: linear-gradient(90deg, transparent, rgb(256,165,0), rgb(256,165,0)); */
  border-radius:  1rem ;
  animation: rotateHue 3s linear infinite alternate-reverse; /* Adjust the duration as needed */
  transition: 2s;

}
@keyframes rotateHue {
  /* 0% {
    background-image: linear-gradient(90deg, transparent,  rgb(256,165,0), rgb(256,165,0));
  }
  100% {
    back
    background-image: linear-gradient(90deg, transparent, rgb(256,165,0), red);

  } */
  0% {
    background-color: red;
    background-image: linear-gradient(90deg, transparent,  rgb(256,165,0), rgb(256,165,0));


  }
  100% {
    background-color: orange;
    background-image: linear-gradient(90deg, transparent, rgb(256,165,0), red);


  }
}




/* this section is for the alert notice section  */
.alert-notice {
  position: absolute;
  bottom: 0px;
  right: 0rem;
  /* padding: 0.5rem; */
  text-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  z-index: 40;
  display: flex;
  gap: 0.5rem;
  justify-content: left;
  align-items: center;
  /* background-color: green; */
  background-color: rgb(240, 82, 82);
  border-radius: 10rem 0 0 10rem;
  border: none;
  transition: 0.3s;
}

#noticeIcon {
  border: 2px solid greenyellow;
  min-width: 2rem;
  min-height: 2rem;
  padding: 0.2rem;
  border-radius: 50%;
  color: var(--primary-color-blue);
  margin: 0.5rem;
}

.alert-notice p {
  font-size: 1rem;
  font-weight: bold;
}