
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.notices {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
    /* align-items: center; */
    /* gap: 2rem; */
}
.notice-textarea{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
}
.notices textarea {
    flex: 1;
    max-width: 100%;
    width: 100%;
    max-height: 5rem;
    margin-bottom: 0px;
}
.past-message-header{
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
    padding-inline: 1rem;
    margin-bottom: 1rem;
    color: var(--primary-color-yellow);

}
.past-messages {
    /* background-color: red; */
    width: 100%;
    min-width: 20rem;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    /* margin-top: 1rem; */
    flex: 1;
}
.past-messages-body{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;


}

.message {
    padding-top: 1rem;
    max-width: 30rem;
    min-width: 100%;
    flex: 1;
    padding-bottom: 1rem;
}
#message-title {
    font-weight: bolder;
    padding-inline-start: 1rem;
    font-weight: normal;

}
#message-body {
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-inline-start: 1rem;

}